import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { IContext, IUserBoostCard, RequirementType } from "../../types/types";
import BoostCard from "../_components/BoostCard/BoostCard";
import { useOutletContext } from "react-router-dom";
import SlideUp from "../_components/SlideUp/SlideUp";
import BoostCardDetails from "../_components/BoostCardDetails/BoostCardDetails";
import { mapRequirementType } from "../../utils/transformation";
import { getTaskHeader } from "../../utils/task";
import { purchaseCardApi } from "../../api/apiFunctions";
import { getFromStorage } from "../../utils/imageUtil";
import Balance from '../_components/Balance/Balance';

const Boost: React.FC = () => {
  const { t } = useTranslation();
  const {
    user, setUser, userBoostCardSections, addReceiptsToReceiptStates, tasks
  } = useOutletContext<IContext>();
  const [currentSection, setCurrentSection] = useState(0);
  const [slideUpShow, setSlideUpShow] = useState(false);
  const [activeCard, setActiveCard] = useState<IUserBoostCard | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  const onSectionClick = (sectionId: number) => {
    setCurrentSection(sectionId);
  }

  const openCardDetails = (card: IUserBoostCard) => {
    if (!slideUpShow && card.requirement === null) {
      setActiveCard(card);
      toggleSlideUpShow(true);
    }
  }

  const toggleSlideUpShow = (isShow: boolean) => {
    setSlideUpShow(isShow);
    if (!isShow) {
      setActiveCard(null);
    }
  }

  const updateCard = async (card: IUserBoostCard) => {
    if (user.coins < card.price) return;
    try {
      const userCopy = { ...user };
      const cardCopy = { ...card, ...{ level: card.level + 1 } };
      const receipt = await purchaseCardApi({ cardId: cardCopy.id });
      userCopy.coins = userCopy.coins - receipt.price;
      userCopy.miningPower = userCopy.miningPower + receipt.boost;

      setUser(userCopy);
      addReceiptsToReceiptStates([receipt]);
      toggleSlideUpShow(false);
    } catch (e) {
      console.error(e);
    }
  }

  const getUnlockMessage = (card: IUserBoostCard) => {
    if (card.requirement) {
      switch (mapRequirementType(card.requirement.type)) {
        case RequirementType.CARD: {
          return t('app.boost.cardRequirements', {
            firstValue: card.requirement.cardLevel, secondValue: t(`app.boost.cards.${card.requirement.translationKey}`)
          });
        }
        case RequirementType.TASK: {
          const task = tasks.find(tsk => tsk.id === card.requirement?.requirementOwnerId);
          if (task) {
            return getTaskHeader(task, t);
          }
          return "";
        }
        default:
          return "";
      }
    }
    return "";
  }

  useEffect(() => {
    if (userBoostCardSections && userBoostCardSections.length > 0 && !initialized) {
      setCurrentSection(userBoostCardSections[0].id);
      setInitialized(true);
    }
  }, [userBoostCardSections]);

  return (
    <div className="stats p-4 pb-24">
      <div className={'flex justify-between mb-4'}>
        <h1 className="text-3xl font-semibold">{t('app.boost.')}</h1>
        <div className={'flex gap-1 items-center'}>
          <Balance
            src={getFromStorage(`coin.png`)}
            alt={'coins:'}
            userCoins={user.coins}
            imgClass="h-5 w-5"
            textClass="block text-lg font-semibold text-center"
          />
        </div>
      </div>
      <div className="flex justify-around gap-2 p-2 items-center w-full rounded-2xl shadow-md border-t bg-stone-800 border-gray-700 z-50 sticky top-6 appearance-left">
        {
          userBoostCardSections?.sort((a, b) => a.ordering - b.ordering).map((section) => (
            <button
              key={section.id}
              onClick={() => onSectionClick(section.id)}
              className={`w-full py-1 rounded-lg transition-colors ${section.id === currentSection ? 'bg-gray-600 text-white' : 'text-gray-500'}`}
            >
              {t(`app.boost.sections.${section.translationKey}`)}
            </button>
          ))
        }
      </div>
      <div className={"py-4"}>
        <ul className={'grid gap-2.5 grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 justify-center appearance-bottom'}>
          {userBoostCardSections?.find(s => s.id === currentSection)?.cards.sort((a, b) => {
            if (a.requirement === null && b.requirement === null) return 0;
            if (a.requirement === null) return Number.MIN_SAFE_INTEGER;
            if (b.requirement === null) return Number.MAX_SAFE_INTEGER;

            return a.totalBoost - b.totalBoost;
          }).map(card => (
            <li
              key={card.id}
              className={"h-fit"}
            >
              <BoostCard
                key={`boost-card-${card.id}`}
                className={"mx-auto h-full"}
                card={card}
                onClick={() => {
                  openCardDetails(card)
                }}
                getUnlockMessage={getUnlockMessage}
              />
            </li>
          ))}
        </ul>
      </div>
      <SlideUp
        isVisible={slideUpShow}
        onCloseButtonClick={() => toggleSlideUpShow(false)}
      >
        {activeCard && (
          <BoostCardDetails
            card={activeCard}
            updateCard={updateCard}
            difference={user.coins - activeCard.price}
          />
        )}
      </SlideUp>
    </div>
  );
};

export default Boost;
