import React from "react";
import LanguageChangeButton from "../LanguageChangeButton/LanguageChangeButton";

const Settings = () => {
  return (
    <div className='w-full'>
      <ul>
        <li>
          <LanguageChangeButton />
        </li>
      </ul>
    </div>
  )
};

export default Settings;
