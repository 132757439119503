export const coinsToNextLevel: number[] = [
  5_000,
  25_000,
  100_000,
  1_000_000,
  2_000_000,
  10_000_000,
  50_000_000,
  100_000_000,
  1_000_000_000,
  10_000_000_000,
  25_000_000_000,
]
