import React, { useRef } from "react";
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from "react-i18next";
import { IAppImage } from "../../../constants/raceImages";

interface ImageSliderProps {
  images: IAppImage[];
  setImageIndex: React.Dispatch<React.SetStateAction<number>>;
  imageIndex: number;
}

const ImageSlider = ({ images, setImageIndex, imageIndex }: ImageSliderProps) => {
  const { t } = useTranslation();
  const imageRefs = useRef<HTMLDivElement[]>([]);

  const showNextImage = () => {
    setImageIndex((index) => (index === images.length - 1 ? images.length - 1 : index + 1));
  };

  const showPreviousImage = () => {
    setImageIndex((index) => (index === 0 ? 0 : index - 1));
  };

  const scrollToCurrentImage = (index: number) => {
    setImageIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedUp: () => showNextImage(),
    onSwipedDown: () => showPreviousImage(),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div className={"flex flex-col relative w-full text-center justify-between grow"}>
      <div {...handlers} className={"flex flex-col gap-8 grow items-center w-full overflow-hidden select-none px-2"}
        style={
          {
            paddingTop: "2rem",
          }
        }
      >
        {images.map(({ alt, getImage }, index) => (
          <div
            key={`div-${alt}`}
            ref={(el) => (imageRefs.current[index] = el!)}
            className={"flex items-center w-full border-gold border rounded-xl gap-2 overflow-hidden shrink-0 p-2"}
            style={{
              transform: `translateY(calc((100px + 2rem ) * ${imageIndex - 1} * -1))`,
              transition: 'transform 0.1s',
              opacity: index === imageIndex ? 1 : 0.4,
              userSelect: 'none',
              background: 'transparent',
              height: '100px',
            }}
            onClick={() => scrollToCurrentImage(index)}
          >
            <div className={"w-10 h-10"}>
              <img alt={alt} src={getImage()}></img>
            </div>
            <p className={"my-2 text-xl text-left font-bold grow-0"}>{t(alt)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
