import { UserSafeModel } from "../../../types/types";
import { roleImages } from "../../../constants/raceImages";
import { mapRace } from "../../../utils/transformation";
import { getFromStorage } from "../../../utils/imageUtil";
import Balance from "../Balance/Balance";
import React from "react";
import { useTranslation } from "react-i18next";

interface UserListItemProps {
  userSafeModel: UserSafeModel;
  userId?: number;
}

const UserListItem = ({ userSafeModel, userId }: UserListItemProps) => {
  const [t] = useTranslation();

  return (
    <li
      className={"bg-gray-primary rounded-2xl"}
      key={userSafeModel.id}
    >
      <div className={"flex p-2"}>
        <div className={"ml-2 mr-4 relative"}>
          <img
            src={userSafeModel.photoUrl ?? roleImages.find(i => mapRace(i.value) === mapRace(userSafeModel.race ?? ""))?.getImage() ?? getFromStorage(`coin.png`)}
            className={"w-12 h-12 object-contain"}
            alt="avatar"
          />
          <div className={"absolute block text-xl text-gold text-center -bottom-2 right-0 font-bold rounded-full "}>
            <span>{userSafeModel.level}</span>
          </div>
        </div>
        <div className={"flex flex-col justify-center"}>
          <p>{userSafeModel.id === userId ? 'You' : userSafeModel.username ?? `${userSafeModel.firstName ?? t('app.stats.fallBackName')} ${userSafeModel.lastName ?? ""}`}</p>
          <div className={"flex items-center"}>
            <span>
              {t('app.stats.miningPower')}
            </span>
            <Balance
              src={getFromStorage(`coin.png`)}
              alt={'coins:'}
              userCoins={userSafeModel.miningPower * 3600}
              imgClass="h-4 w-4 ml-2 mr-1"
            />
            <span>
              {t('app.stats.perHour')}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
}

export default UserListItem;
