import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { useTranslation } from "react-i18next";

const LanguageChangeButton = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(i18n.language || 'en');

  const inputProps = useMemo(() => ({
    'aria-label': t('app.locales.aria.label'),
  }), [t]);

  const handleLanguageClick = (language: string) => {
    handleLanguageChange(language);
    setIsOpen(open => !open);
  };

  const changeLanguage = useCallback(async (newLanguage: string) => {
    try {
      await i18n.changeLanguage(newLanguage);
      setLanguage(newLanguage);
    } catch (error) {
      throw new Error('Error changing language');
    }
  }, [i18n]);

  const handleLanguageChange = useCallback((newLanguage: string) => {
      changeLanguage(newLanguage).catch(() => {
        throw new Error('Error changing language');
      });
    },
    [changeLanguage],
  );

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const menuItems = [
    { value: 'en', label: t('app.locales.en') },
    { value: 'uk', label: t('app.locales.uk') },
    { value: 'ru', label: t('app.locales.ru') },
    { value: 'pl', label: t('app.locales.pl') },
    { value: 'ar', label: t('app.locales.ar') },
    { value: 'de', label: t('app.locales.de') },
    { value: 'es', label: t('app.locales.es') },
    { value: 'fr', label: t('app.locales.fr') },
    { value: 'hi', label: t('app.locales.hi') },
    { value: 'zh-CN', label: t('app.locales.zh') }
  ];

  return (
    <div className={`bg-gold rounded-xl p-[1px] relative ${isOpen && 'rounded-b-none'}`}>
      <div className={`bg-grayPrimary rounded-xl px-2 h-full ${isOpen && 'rounded-b-none'}`}>
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={'text-white text w-full flex justify-between py-4'}
          aria-label={inputProps['aria-label']}
        >
          <span className={"w-full"}>{t('app.settings.chooseLanguage')}</span>
          <span className={'mt-2 border-t-8 border-x-8 border-x-transparent border-t-white'}></span>
        </button>
        {isOpen && (
          <div className={'bg-gold p-[1px] pt-0 rounded-b-xl w-full left-0 absolute'}>
            <ul
              className={"bg-grayPrimary text-white rounded-b-xl"}
              role="listbox"
            >
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  role="option"
                  aria-selected={language === item.value}
                  onClick={() => handleLanguageClick(item.value)}
                  className={'cursor-pointer border-gold aria-selected:bg-graySecondary hover:bg-graySecondary border-b last:border-0 first:border-t last:rounded-b-xl'}
                >
                  <button className="w-full text-start px-2 py-4">
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageChangeButton;
