import React, { useState } from "react";
import { IUserBoostCard } from "../../../types/types";
import { useTranslation } from "react-i18next";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import { getFromStorage } from "../../../utils/imageUtil";
import Balance from "../Balance/Balance";

interface BoostCardDetailsProps {
  card: IUserBoostCard;
  difference: number;
  updateCard: (boostCard: IUserBoostCard) => Promise<void>;
}

const BoostCardDetails = ({ card, updateCard, difference }: BoostCardDetailsProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const disabled = difference < 0;

  const onPurchaseButtonClick = () => {
    if (loading || disabled) return;
    setLoading(true);
    updateCard(card).finally(() => setLoading(false));
  }

  return (
    <>
      <div className={"w-48 rounded-2xl overflow-hidden"}>
        <img
          alt={"img"}
          src={getFromStorage(`cards/${card.translationKey}.png`)}
        ></img>
      </div>
      <span className={"text-2xl bold text-center"}>
         {t(`app.boost.cards.${card.translationKey}`)}
      </span>
      <div className="flex flex-col gap-1">
        <span className={"text-gray-400"}>
          {t(`app.boost.profitPerHour`)}
        </span>
        <div className="flex font-bold justify-center items-center text-xl">
          <span className={"font-bold mr-1 leading-4"}>
            +
          </span>
          <Balance
            src={getFromStorage(`coin.png`)}
            alt="coin"
            userCoins={card.nextLevelBoost - card.totalBoost}
            imgClass={'h-4 w-4'}
            textClass={'pl-1 leading-4'}
          />
        </div>
      </div>
      <div className={"grow"}></div>
      <div
        className={`flex justify-center items-center rounded-2xl overflow-hidden w-full h-12 font-bold px-2 z-0
          ${loading || disabled ? 'bg-gray-800' : 'clickable bg-green-950'}`
        }
        onClick={() => {
          onPurchaseButtonClick()
        }}
      >
        {
          loading ? <ProgressLoader /> : disabled ? (
            <>
              <div>
                {t(`app.boost.notEnoughCoins`)}
              </div>
              <div className="ml-2 w-5">
                <img
                  src={getFromStorage(`coin.png`)}
                  alt="coin"
                />
              </div>
              <div className="ml-1">
                {Math.round(Number(difference) * Number(-1))}
              </div>
            </>
          ) : (
            <div
              className={"flex"}
            >
              <div>
                {card.level === 0 ? t(`app.boost.purchase`) : t(`app.boost.update`)}
              </div>
              <div className="ml-2 w-5">
                <img
                  src={getFromStorage(`coin.png`)}
                  alt="coin"
                />
              </div>
              <div className="ml-1">
                {Math.round(card.price)}
              </div>
            </div>
          )
        }
      </div>
    </>
  )
}

export default BoostCardDetails;
