import { ReactNode } from "react";

interface SlideUpProps {
  isVisible: boolean;
  children: ReactNode;
  onCloseButtonClick: () => void;
}

const SlideUp = ({ isVisible, children, onCloseButtonClick }: SlideUpProps) => {
  return (
    <div
      className={`fixed bottom-0 left-0 right-0 transform transition-transform duration-200 ease-in-out ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      } shadow-lg z-50 h-dvh`}
    >
      <div className={'flex flex-col h-dvh'}>
        <div
          className={"bg-transparent backdrop-blur-sm h-1/3"}
          onClick={() => onCloseButtonClick()}
        >
        </div>
        <div
          className={"flex flex-col grow backdrop-blur-sm"}
        >
          <div
            className={'overflow-hidden flex justify-end gap-2 pt-4 px-4 rounded-t-full border-t-2 border-gold bg-gray-primary border-gold z-0'}
            style={{
              boxShadow: isVisible ? '0 0 16px 8px rgba(255, 191, 0, 0.7)' : ''
            }}
          >
            <button
              className={"right-4 w-6 h-6 bg-gray-600 rounded-full text-xl leading-6"}
              onClick={() => {
                onCloseButtonClick()
              }}
            >
              &#215;
            </button>
          </div>
          <div className={"overflow-auto flex flex-col grow gap-4 items-center bg-gray-primary z-20 p-4 pb-11"}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideUp;
