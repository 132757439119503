import { getFromStorage } from "../utils/imageUtil";

export interface IAppImage {
  getImage: (level?: string | number) => any,
  value: string,
  alt: string,
}

export const genderImages: IAppImage[] = [
  {
    getImage: () => {
      return getFromStorage(`female.png`);
    },
    value: "female",
    alt: "app.character.female"
  },
  {
    getImage: () => {
      return getFromStorage(`male.png`);
    },
    value: "male",
    alt: "app.character.male",
  },
];

export const maleGenderImages: IAppImage[] = [
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/dark_elf/${level}.PNG`);
    },
    value: "dark_elf",
    alt: "app.character.dark_elf"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/gnome/${level}.PNG`);
    },
    value: "gnome",
    alt: "app.character.gnome"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/human/${level}.PNG`);
    },
    value: "human",
    alt: "app.character.human"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/light_elf/${level}.PNG`);
    },
    value: "light_elf",
    alt: "app.character.light_elf"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/orc/${level}.PNG`);
    },
    value: "orc",
    alt: "app.character.orc"
  },
];

export const femaleGenderImages: IAppImage[] = [
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/dark_elf/${level}.PNG`);
    },
    value: "dark_elf",
    alt: "app.character.dark_elf"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/gnome/${level}.PNG`);
    },
    value: "gnome",
    alt: "app.character.gnome"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/human/${level}.PNG`);
    },
    value: "human",
    alt: "app.character.human"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/light_elf/${level}.PNG`);
    },
    value: "light_elf",
    alt: "app.character.light_elf"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/orc/${level}.PNG`);
    },
    value: "orc",
    alt: "app.character.orc"
  },
];

export const roleImages: IAppImage[] = [
  {
    getImage: () => {
      return getFromStorage(`dark_elf.png`);
    },
    value: "dark_elf",
    alt: "app.character.dark_elf"
  },
  {
    getImage: () => {
      return getFromStorage(`gnome.png`);
    },
    value: "gnome",
    alt: "app.character.gnome"
  },
  {
    getImage: () => {
      return getFromStorage(`human.png`);
    },
    value: "human",
    alt: "app.character.human"
  },
  {
    getImage: () => {
      return getFromStorage(`light_elf.png`);
    },
    value: "light_elf",
    alt: "app.character.light_elf"
  },
  {
    getImage: () => {
      return getFromStorage(`orc.png`);
    },
    value: "orc",
    alt: "app.character.orc"
  },
];
