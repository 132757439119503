import React, { CSSProperties } from "react";
import { getFromStorage } from "../../../utils/imageUtil";

interface ProgressLoaderProps {
  startScreen?: boolean;
}

const ProgressLoader = ({ startScreen }: ProgressLoaderProps) => {

  const style: CSSProperties = !!startScreen ? {
    backgroundImage: `url(${getFromStorage('startScreen.png')})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
  } : {};

  return (
    <div
      className={"flex flex-col items-center justify-center  m-auto h-dvh content-center"}
      style={style}
    >
      <div className={"loader h-32 w-32"}></div>
    </div>
  )
}

export default ProgressLoader;
