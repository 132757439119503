import { IDailyBonusModel, IUserModel } from "../../../types/types";
import DailyBonusCell from "./DailyBonusCell";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { formatTimeHHMMSS } from "../../../utils/time";
import ProgressLoader from "../ProgressLoader/ProgressLoader";

interface DailyBonusProps {
  userModel: IUserModel;
  claimBonus: (dailyBonusModel: IDailyBonusModel) => Promise<void>;
  dailyBonusModels: IDailyBonusModel[];
  timeToUnlockBonusButton: string | null;
  setTimeToUnlockBonusButton: (text: string | null) => void;
}

const DailyBonus = ({
  userModel, claimBonus, dailyBonusModels, timeToUnlockBonusButton, setTimeToUnlockBonusButton
}: DailyBonusProps) => {
  const DAY_SECONDS = 86400;
  const [t] = useTranslation();
  const [currentBonus, setCurrentBonus] = useState<IDailyBonusModel | null>(null);
  const [timeToClaimBonus, setTimeToClaimBonus] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const recalculateTime = () => {
    if (!userModel.lastBonusClaimed) {
      setTimeToUnlockBonusButton(null);
      if (!userModel.lastBonusClaimed || userModel.lastBonusClaimedId === dailyBonusModels[dailyBonusModels.length - 1].id) {
        setTimeToClaimBonus(null);
      }
    } else {
      const lastBonusClaimedSeconds = Math.round(new Date(userModel.lastBonusClaimed).getTime() / 1000);
      const nowSeconds = Math.round(new Date().getTime() / 1000);

      const secondsToUnlock = DAY_SECONDS - (nowSeconds - lastBonusClaimedSeconds);
      if (secondsToUnlock > 0) {
        setTimeToUnlockBonusButton(formatTimeHHMMSS(secondsToUnlock))
        setTimeToClaimBonus(null)
      } else {
        setTimeToUnlockBonusButton(null)
        const secondsToClaim = DAY_SECONDS * 2 - (nowSeconds - lastBonusClaimedSeconds);

        if (secondsToClaim > 0 && secondsToClaim < DAY_SECONDS) {
          setTimeToClaimBonus(formatTimeHHMMSS(Math.round(secondsToClaim)))
        } else {
          setTimeToClaimBonus(null)
        }
      }
    }
  }

  const refreshCurrentBonus = () => {
    dailyBonusModels.forEach(dBM => {
      setCurrentBonusModel(dBM)
    });
    recalculateTime();
  }

  useEffect(() => {

    const timeInterval = setInterval(() => {
      if (userModel === null || (!timeToClaimBonus && !timeToUnlockBonusButton)) {
        clearInterval(timeInterval);
      } else {
        recalculateTime();
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [timeToClaimBonus, timeToUnlockBonusButton]);


  const getClaimBonusClick = () => {
    if (currentBonus) {
      setLoading(true);
      claimBonus(currentBonus).then(() => {
        refreshCurrentBonus();
      }).finally(() => setLoading(false));
    }
  }

  const isClaimed = (dailyBonusModel: IDailyBonusModel): boolean => {
    return dailyBonusModel.id < (currentBonus?.id ?? -1);
  }

  const setCurrentBonusModel = (dailyBonusModel: IDailyBonusModel) => {
    if (!dailyBonusModels || dailyBonusModels.length === 0) return false;

    const lastIndex = dailyBonusModels.length - 1;
    let currentBonusModel: IDailyBonusModel = dailyBonusModels[0];

    const nowSeconds = Math.round(new Date().getTime() / 1000);
    const lastBonusClaimed = new Date(userModel.lastBonusClaimed ?? "").getTime() / 1000;
    const expired = userModel.lastBonusClaimed && (nowSeconds - lastBonusClaimed) > DAY_SECONDS * 2;
    if (
      (
        (userModel.lastBonusClaimedId === null || userModel.lastBonusClaimedId === dailyBonusModels[lastIndex].id || expired)
        && dailyBonusModel.id === currentBonusModel.id
      )
    ) {
      console.log(123)
      setCurrentBonus(dailyBonusModel);
      return;
    }

    const previousBonusModel = dailyBonusModels.find(dBM => dBM.id === userModel.lastBonusClaimedId);
    if (!previousBonusModel) {
      return;
    }

    const indexOfCurrentBonusModel = dailyBonusModels.indexOf(previousBonusModel) + 1;
    currentBonusModel = dailyBonusModels[indexOfCurrentBonusModel];
    if (currentBonusModel && currentBonusModel.id === dailyBonusModel.id && !expired) {
      setCurrentBonus(dailyBonusModel)
    }
  }


  useEffect(() => {
    refreshCurrentBonus();
  }, [userModel]);

  return (
    <div className={"flex flex-col gap-4 w-full grow"}>
      <h2 className={"font-bold text-2xl text-center"}>{t('app.dailyBonus.')}</h2>
      <ul className={'grid gap-2.5 grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 justify-center'}>
        {
          dailyBonusModels.map((dailyBonusModel, index) => {
            const isCurrent = (currentBonus?.id ?? -1) === dailyBonusModel.id;
            return (
              <li key={index}>
                <DailyBonusCell
                  dailyBonusModel={dailyBonusModel}
                  header={t('app.dailyBonus.day', { value: index + 1 }).toUpperCase()}
                  claimed={isClaimed(dailyBonusModel)}
                  current={isCurrent}
                  timeRemaining={isCurrent ? timeToClaimBonus : null}
                />
              </li>
            )
          })
        }
      </ul>
      <div className={"grow"}></div>
      {
        loading ?
          <ProgressLoader />
          :
          timeToUnlockBonusButton !== null ?
            (
              <button className={"bg-graySecondary rounded-xl py-3 px-6 w-full h-14 overflow-hidden"}>
                {timeToUnlockBonusButton}
              </button>
            )
            :
            (
              <button
                className={"bg-amber-900 text-xl font-bold rounded-xl py-3 px-6 w-full h-14 overflow-hidden"}
                onClick={getClaimBonusClick}
              >
                {t('app.dailyBonus.get')}
              </button>
            )
      }
    </div>
  )
}

export default DailyBonus;
