import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="w-full">
      <button
        onClick={handleBackClick}
        className="p-2 m-4 text-2xl font-bold"
      >
        {"<"}
      </button>
      <p className="p-4 text-3xl font-semibold">{t('app.comingSoon')}</p>
    </div>
  );
};

export default ComingSoon;
