import { mapTaskType } from "./transformation";
import { ITaskDTO, TaskType } from "../types/types";
import { TFunction } from "i18next";
import { getFromStorage } from "./imageUtil";

export const getImageByTaskType = (type: string) => {
  switch (mapTaskType(type)) {
    case TaskType.INSTAGRAM:
      return getFromStorage(`instagram.png`);
    case TaskType.TELEGRAM:
      return getFromStorage(`telegram.png`);
    case TaskType.X:
      return getFromStorage(`twitter-x.png`);
    case TaskType.YOUTUBE:
      return getFromStorage(`youtube.png`);
    case TaskType.TIKTOK:
      return getFromStorage(`tiktok.png`);
    default:
      return getFromStorage(`coin.png`);
  }
};

export const getTaskHeader = (task: ITaskDTO, t:TFunction<"translation", undefined>): string => {
  return t(`app.tasks.actionType.${task.action.toLowerCase()}`, {
    value: task.header ?? "",
    value1: task.type !== undefined ? t(`app.tasks.taskType.${task.type.toLowerCase()}`) : ""
  })
}
