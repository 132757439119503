import { getFromStorage } from "../../utils/imageUtil";

interface ErrorPageProp {
  errorMessage: string;
}


const ErrorPage = ({ errorMessage }: ErrorPageProp) => {
  const bgImageUrl = require(getFromStorage(`fantasy_scene.png`));
  return (
    <div style={{background: `url(${bgImageUrl})`, backgroundSize: 'cover'}} className={`w-full h-screen p-4 bg-contain text-center pt-40`}>
      <h2 className="text-3xl">Error. The Path is Lost in the Enchanted Forest"</h2>
      <p>Our hero has wandered off the beaten trail, and the way forward is shrouded in mystery. Perhaps a step back will reveal a clearer path.</p>
      <p className="mt-10 text-xl">
        {errorMessage}
      </p>
    </div>
  );
};

export default ErrorPage;
