import {
  RequirementType,
  TaskType,
  ActionType, Gender, Race
} from '../types/types';

const mapEnum = <T extends { [key: string]: string | number }>(enumType: T, value: string | undefined): T[keyof T] => {
  if (value != null && Object.values(enumType).includes(value.toUpperCase())) {
    return value.toUpperCase() as T[keyof T];
  }
  return enumType.UNDEFINED as T[keyof T];
};

export const mapRequirementType = (num: string | undefined): RequirementType => mapEnum(RequirementType, num);
export const mapTaskType = (num: string | undefined): TaskType => mapEnum(TaskType, num);
export const mapActionType = (num: string | undefined): ActionType => mapEnum(ActionType, num);
export const mapGender = (num: string | undefined): Gender => mapEnum(Gender, num);
export const mapRace = (num: string | undefined): Race => mapEnum(Race, num);
