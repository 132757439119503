import React, { useState, useEffect, ErrorInfo } from 'react';
import { ErrorBoundaryProps } from "../../../types/types";
import ErrorPage from '../../pages/ErrorPage';

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [errorInfo, setErrorInfo] = useState("");

  const handleError = (error: Error, errorInfo: ErrorInfo) => {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    setErrorInfo(error.message);
  };

  useEffect(() => {
    const handleUnhandledError = (event: ErrorEvent) => {
      setHasError(true);
      handleError(event.error, { componentStack: '' });
    };

    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      setHasError(true);
      handleError(event.reason, { componentStack: '' });
    };

    window.addEventListener('error', handleUnhandledError);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleUnhandledError);
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  if (hasError) {
    return <ErrorPage errorMessage={errorInfo} />
  }

  return <>{children}</>;
};

export default ErrorBoundary;
