import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ActionType, IContext, Section, ITaskDTO, TaskType } from "../../types/types";
import { useTranslation } from "react-i18next";
import TaskListItem from "../_components/TaskListItem/TaskListItem";
import { mapActionType } from "../../utils/transformation";
import { finishTaskApi, startTaskApi } from "../../api/apiFunctions";

const Tasks = () => {
  const [t] = useTranslation();
  const { user, setUser, tasks } = useOutletContext<IContext>();
  const [tasksInLoading, setTasksInLoading] = useState<number[]>([]);

  const handleTask = (task: ITaskDTO) => {
    switch (mapActionType(task.action)) {
      case ActionType.CONNECT:
      case ActionType.JOIN:
      case ActionType.WATCH:
      case ActionType.SUBSCRIBE:
      case ActionType.REDIRECT:
        if (task.type === TaskType.TELEGRAM) {
          Telegram.WebApp.openTelegramLink(task.payload);
        } else {
          Telegram.WebApp.openLink(task.payload);
        }
        break;
      default:
        break;
    }
  };

  const isTaskCompleted = (task: ITaskDTO) => {
    switch (mapActionType(task.action)) {
      case ActionType.MINE:
        return Number(user.coins) >= Number(task.payload);
      case ActionType.INVITE:
        return Number(user.friendsIds.length) >= Number(task.payload);
      default:
        return true;
    }
  };

  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const startTask = async (taskId: number) => {
    const task = tasks.find(t => t.id === taskId);

    if (task) {
      try {
        setTasksInLoading([...tasksInLoading, taskId]);
        await startTaskApi({ taskId: taskId });
        handleTask(task);
        setUser({ ...user, startedTasksIds: [...user.startedTasksIds, taskId] })
        await wait(5000);
      } catch (e) {
        console.error(e);
      } finally {
        setTasksInLoading(tasksInLoading.filter(id => id !== taskId));
      }
    }
  };

  const completeTask = async (taskId: number) => {
    const task = tasks.find(t => t.id === taskId);
    if (task) {
      if (!isTaskCompleted(task)) return;

      try {
        await finishTaskApi({ taskId: taskId });
        const userModel = {
          ...user,
          ...{
            finishedTasksIds: [...user.finishedTasksIds, taskId],
            coins: Number(user.coins) + Number(task.reward)
          }
        }
        setUser(userModel);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const promoTasks = tasks.filter(t => t.sectionTranslationKey === Section.PROMO && !user.finishedTasksIds.some(ft => ft === t.id));
  const socialsTasks = tasks.filter(t => t.sectionTranslationKey === Section.SOCIALS && !user.finishedTasksIds.some(ft => ft === t.id));
  const mainTasks = tasks.filter(t => t.sectionTranslationKey === Section.TASKS && !user.finishedTasksIds.some(ft => ft === t.id));
  const otherTasks = tasks.filter(
    t => t.sectionTranslationKey !== Section.PROMO && t.sectionTranslationKey !== Section.SOCIALS && t.sectionTranslationKey !== Section.TASKS && !user.finishedTasksIds.some(ft => ft === t.id)
  );
  const finishedTasks = tasks.filter(t => user.finishedTasksIds.some(ft => ft === t.id));


  return (
    <div className="tasks px-4 pb-24">
      <h2 className="text-3xl font-semibold py-4">{t('app.tasks.')}</h2>
      {tasks.length > 0 ?
        <>
          {promoTasks.length > 0 && <>
            <h3 className={"text-2xl font-semibold py-2 appearance-left"}>{t('app.tasks.promo')}</h3>
            <ul className={"flex flex-col gap-2 pb-2 appearance-top"}>
              {promoTasks.map((task) => (
                  <TaskListItem
                    key={task.id}
                    task={task}
                    user={user}
                    loading={tasksInLoading.includes(task.id)}
                    isTaskCompleted={isTaskCompleted}
                    completeTask={completeTask}
                    startTask={startTask}
                  />
                )
              )}
            </ul>
          </>}
          {socialsTasks.length > 0 && <>
            <h3 className={"text-2xl font-semibold py-2 appearance-left"}>{t('app.tasks.socials')}</h3>
            <ul className={"flex flex-col gap-2 pb-2 appearance-top"}>
              {socialsTasks.map((task) => (
                  <TaskListItem
                    key={task.id}
                    task={task}
                    user={user}
                    loading={tasksInLoading.includes(task.id)}
                    isTaskCompleted={isTaskCompleted}
                    completeTask={completeTask}
                    startTask={startTask}
                  />
                )
              )}
            </ul>
          </>}
          {mainTasks.length > 0 && <>
            <h3 className={"text-2xl font-semibold py-2 appearance-left"}>{t('app.tasks.tasks')}</h3>
            <ul className={"flex flex-col gap-2 pb-2 appearance-top"}>
              {mainTasks.map((task) => (
                  <TaskListItem
                    key={task.id}
                    task={task}
                    user={user}
                    loading={tasksInLoading.includes(task.id)}
                    isTaskCompleted={isTaskCompleted}
                    completeTask={completeTask}
                    startTask={startTask}
                  />
                )
              )}
            </ul>
          </>}
          {otherTasks.length > 0 && <>
            <h3 className={"text-2xl font-semibold py-2 appearance-left"}>{t('app.tasks.other')}</h3>
            <ul className={"flex flex-col gap-2 pb-2 appearance-top"}>
              {otherTasks.map((task) => (
                  <TaskListItem
                    key={task.id}
                    task={task}
                    user={user}
                    loading={tasksInLoading.includes(task.id)}
                    isTaskCompleted={isTaskCompleted}
                    completeTask={completeTask}
                    startTask={startTask}
                  />
                )
              )}
            </ul>
          </>}
          {finishedTasks.length > 0 && <>
            <h3 className={"text-2xl font-semibold py-2 appearance-left"}>{t('app.tasks.finished')}</h3>
            <ul className={"flex flex-col gap-2 pb-2 appearance-top"}>
              {finishedTasks.map((task) => (
                  <TaskListItem
                    key={task.id}
                    task={task}
                    user={user}
                    loading={tasksInLoading.includes(task.id)}
                    isTaskCompleted={isTaskCompleted}
                    completeTask={completeTask}
                    startTask={startTask}
                  />
                )
              )}
            </ul>
          </>}
        </>
        :
        <p className={"text-xl"}>{t('app.tasks.youHaveNoTasks')}</p>
      }
    </div>
  );
};

export default Tasks;
