import httpClient from './httpClient';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

class ApiWrapper {
  constructor() {
    httpClient.defaults.headers.common['Init-Data'] = Telegram.WebApp.initData;
  }

  public async get<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await httpClient.get(endpoint, config);
    return response.data;
  }

  public async post<T, D = any>(endpoint: string, data?: D, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await httpClient.post(endpoint, data, config);
    return response.data;
  }

  public async put<T, D = any>(endpoint: string, data?: D, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await httpClient.put(endpoint, data, config);
    return response.data;
  }
}

export default ApiWrapper;
