import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { IContext, UserSafeModel } from "../../types/types";
import { appConfig } from "../../app/appConfig"
import { useTranslation } from "react-i18next";
import ProgressLoader from "../_components/ProgressLoader/ProgressLoader";
import { getFriendsApi } from "../../api/apiFunctions";
import { getFromStorage } from "../../utils/imageUtil";
import UserListItem from "../_components/UserListItem/UserListItem";

const Friends: React.FC = () => {
  const [t] = useTranslation();
  const { user } = useOutletContext<IContext>();
  const [referrals, setReferrals] = useState<UserSafeModel[] | null>(null);
  const [referralLink, setReferralLink] = useState("");

  useEffect(() => {
    if (!referrals && user) {
      setReferralLink(t('app.friends.promoLink', { value: appConfig.botLink, value1: user.id }));

      if (user.friendsIds && user.friendsIds.length > 0) {
        getFriendsApi().then(response => {
          setReferrals(response.friends as UserSafeModel[]);
        }).catch(() => {
          setReferrals([]);
        });
      } else {
        setReferrals([]);
      }
    }
  }, [referrals, user]);

  const handleCopyClick = () => {
    if (referralLink && navigator) {
      navigator.clipboard.writeText(referralLink).then(() => {
        console.log('Text copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  }

  const handleShareClick = () => {
    Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${referralLink}&text=${t('app.friends.shareComment')}`)
  }

  if (!referrals) {
    return <ProgressLoader />
  }

  const invitedFriends = referrals.filter((ref) => ref.id !== user.referredBy);

  return (
    <div className="referral p-4 flex flex-col pb-24 h-dvh overflow-y-scroll">
      <h2 className="text-3xl font-semibold mb-4 w-full block">{t('app.friends.referrals')}</h2>
      <div className={"pb-16"}>
        {referrals.length > 0 ? (
            <>
              {user.referredBy && (
                <>
                  <h3 className="text-xl font-semibold w-full block mt-2 appearance-left">{t('app.friends.invitedBy')}</h3>
                  <ul className={"flex flex-col gap-2 my-2 appearance-right"}>
                    {referrals.filter(ref => ref.id === user.referredBy).map(referral => (
                      <UserListItem
                        key={referral.id}
                        userSafeModel={referral}
                        userId={user.id}
                      />
                    ))}
                  </ul>
                </>
              )}
              {invitedFriends.length > 0 && (
                <>
                  <h3 className="text-xl font-semibold w-full block mt-2 appearance-left">{t('app.friends.invitedFriends', { value: invitedFriends.length.toString() })}</h3>
                  <ul className={"flex flex-col gap-2 my-2 appearance-bottom"}>
                    {invitedFriends.sort((a, b) => a.miningPower - b.miningPower).map(referral => (
                      <UserListItem
                        key={referral.id}
                        userSafeModel={referral}
                        userId={user.id}
                      />
                    ))}
                  </ul>
                </>
              )}
            </>
          )
          :
          (
            <span>{t('app.friends.youHaveNoReferrals')}</span>
          )
        }
      </div>
      <div className={"flex absolute gap-2 bottom-24 left-4 right-4 appearance-bottom"}>
        <button
          onClick={handleShareClick}
          className={'flex w-full justify-center items-center bg-tg-blue px-4 h-12 font-semibold rounded-2xl shadow-md bottom-24 left-4 right-4 mb-2 clickable pulse'}
        >
        <span className={"text-xl w-6 h-6 font-semibold inline-block"}>
          <img
            className={"w-fit"}
            src={getFromStorage(`tg-logo.png`)}
            alt=""
          />
        </span>
          <span className={"text-xl h-6 font-semibold"}>{t('app.friends.shareTelegram')}</span>
        </button>
        <button
          onClick={handleCopyClick}
          className={'flex w-24 justify-center items-center bg-green-950 px-4 h-12 font-semibold rounded-2xl shadow-md bottom-24 left-4 right-4 mb-2 clickable'}
        >
          <span className={"text-xl w-6 h-6 font-semibold inline-block"}>
            <img
              className={"w-fit invert"}
              src={getFromStorage(`copy-icon.svg`)}
              alt=""
            />
          </span>
        </button>
      </div>
    </div>
  );
}

export default Friends;
